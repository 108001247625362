.option-button__button {
  background: rgba(181, 92, 83, 0.2);
  border: 1px solid var(--color-bronze);
  transition: background linear 0.1s;
  border-radius: 3px;
  padding: 0.5rem;
  cursor: pointer;
  color: var(--color-bronze);
  font-weight: 800;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  text-align: left;
}

.option-button__button:hover {
  background: rgba(181, 92, 83, 0.6);
}

.option-buton__option-key {
  background: white;
  width: 1rem;
  height: 1rem;
  margin-right: 0.2rem;
  text-align: center;
  line-height: 1.1rem;
  border-radius: 2px;
}
